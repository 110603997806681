import axios from 'axios'
import store from '../store'
import socketClient from 'socket.io-client';
import 'regenerator-runtime/runtime'
import moment from 'moment';
import * as THREE from "three";
// import {clear} from "core-js/internals/task";


const protocol = window.location.protocol; // 현재 프로토콜
const host = window.location.hostname; // 현재 접속 호스트
const wlPort = window.location.port; // 현재 접속 포트

let port = "80";
if(protocol==="https:"){
  port = "443"
}else{
  port = (wlPort==='8800'|| wlPort==='8080')? "9900":"";
}
const apiServer = `${protocol}//${host}:${port}`;

// const webServer = `${protocol}//${host}:${wlPort}`;


const axiosConfig = {
  timeout: 0,
  responseType: "json"
}; // request time out 10 minutes;

( async ()=>{
  await getFrontMode(); // dark mode, light mode
})();

export const GL2LTR = 3.78541;
export const LTR2GL = 0.264172;




export async function initBase(){
  try{
    await setCodes();
    await setTanks();
    await setAreas();
    await setOil();
    await setAlarm();
    // await connectWebSocket('CENTER1', 'FFFFFFFFFF');
    return true;

  }catch(err){
    console.error("[init-base] error--->", err);
    return false;
  }
}

let voices = [];

let voiceFound = false;
const voiceLang = 'ko-KR';
let voicesKr = [];


function setVoiceList(){
  voices = window.speechSynthesis.getVoices();
  // console.log( "setVoiceList --- ", voices.length );

  for(let i = 0; i < voices.length ; i++) {
    if( voices[i].lang.indexOf(voiceLang) >= 0 || voices[i].lang.indexOf(voiceLang.replace('-', '_')) >= 0) {
      voicesKr.push(i);
// eslint-disable-next-line no-unused-vars
      voiceFound = true;
    }
  }
  if(voicesKr.length) store.state.voice = voices[voicesKr[voicesKr.length-1]];

  // console.log( "@@@@@@@@@@ Korean voices --->", voicesKr );
}

if(window.speechSynthesis.onvoiceschanged !== undefined) {
  window.speechSynthesis.onvoiceschanged = setVoiceList
}

let isSpeaking = false;
export function speech(text){
  let utterThis = null;
  try{
    if(!voiceFound) return;
    // console.log( "-------------voices-------------->", voices );
    if(!store.state.soundOn) return;

/*
    if(!window.speechSynthesis) {
      // console.warn("음성 재생을 지원하지 않는 디바이스");
      return;
    }
*/
    if(store.state.voice===null) {
      // console.warn('voice not found');
      return false;
    }

    if(!isSpeaking) return;

    isSpeaking = true;

    /*
    if(!voicesKr.length) return false;
    if(!voiceFound) {
      console.error('voice not found');
      return false;
    }
    */

    utterThis = new SpeechSynthesisUtterance(text);
/*
    utterThis.onend = function (event) {
      // console.log('end', event);
    };
    utterThis.onerror = function(event) {
      console.log('error', event);
      return false;
    };
*/


    // utterThis.voice = voices[voicesKr[voicesKr.length-1]];
    utterThis.voice = store.state.voice;
    utterThis.lang = voiceLang;
    utterThis.pitch = 1;
    utterThis.rate = 1; // 속도
    window.speechSynthesis.speak(utterThis);
    return true;
  }catch(err){
    console.error( "[utils] speech --- error", err );
    utterThis?.cancel();
    return false;
  }finally{
    utterThis = null;
    isSpeaking = false;
  }
}

/**
 * Helper function to emit a beep sound in the browser using the Web Audio API.
 *
 * @param {number} duration - The duration of the beep sound in milliseconds.
 * @param {number} frequency - The frequency of the beep sound.
 * @param {number} volume - The volume of the beep sound.
 *
 * @returns {Promise} - A promise that resolves when the beep sound is finished.
 */
const audioContext = new AudioContext(); // for beep sound
export function beep(duration, frequency, volume){
  return new Promise((resolve, reject) => {
    // Set default duration if not provided
    duration = duration || 110;
    frequency = frequency || 440;
    volume = volume || 100;

    let oscillatorNode;
    let gainNode;
    try{
      oscillatorNode = audioContext.createOscillator();
      gainNode = audioContext.createGain();

      oscillatorNode.connect(gainNode);
      // Set the oscillator frequency in hertz
      oscillatorNode.frequency.value = frequency;
      // Set the type of oscillator
      oscillatorNode.type= "square";
      gainNode.connect(audioContext.destination);
      // Set the gain to the volume
      gainNode.gain.value = volume * 0.01;
      // Start audio with the desired duration
      oscillatorNode.start(audioContext.currentTime);
      oscillatorNode.stop(audioContext.currentTime + duration * 0.001);
      // Resolve the promise when the sound is finished
      oscillatorNode.onended = () => {
        oscillatorNode.disconnect();
        gainNode.disconnect();
        oscillatorNode = null;
        gainNode = null;

        return resolve();
      };

    }catch(error){
      return reject(error);
    }
  });
}

export async function audioEnable(){
  try{
    await audioContext.resume();
    await window.speechSynthesis.resume();
  }catch(err){
    console.error(err);
  }
}

let isBeepProc = false;
export async function beepSound(type=null){
  // console.log( "--------------------beepSound--------------------");
  if(!store.state.soundOn) return;
  if(isBeepProc) return;

  isBeepProc = true;
  try{
    switch(type){
      case 'tick': // timer
        await beep(20, 4200, 15);
        await beep(20, 3600, 5);
        break;
      case 'ok':
        await beep(30, 2800);
        await beep(30, 3500);
        break;
      case 'info': // info
        await beep(10, 3520, 40);
        await beep(10, 3920, 20);
        break;
      case 'primary': // message, info
        await beep(30, 2800, 50);
        await beep(20, 2000, 50);
        break;
      case 'message': // Event
        await beep(20, 1048, 50);
        await beep(30, 1176, 60);
        await beep(40, 1320, 80);
        await beep(20, 1400, 90);
        break;
      case 'success':
        await beep(25, 2000, 50);
        await beep(15, 1120, 50);
        break;
      case 'warning': // warning
        await beep(20, 2400, 60);
        await beep(20, 3220, 40);
        break;
      case 'danger': // danger
        await beep(20, 3520, 70);
        await beep(20, 2700, 40);
        await beep(10, 2000, 20);
        break;

      default:
        await beep(20, 3520, 20);
        await beep(20, 4200, 10);
        break;
    }
  }catch(err){
    console.error(err);
  }finally{
    isBeepProc = false;
  }
}

export function sleep(ms){
  return new Promise((r) => setTimeout(r, ms));
}


export function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function iosDtToLocal(v){
  return v?moment(v).format("YYYY-MM-DD HH:mm"):''
}

export function getRandomHex( size ) {
  let result = [];
  let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

  for (let n = 0; n < size; n++) {
    result.push(hexRef[Math.floor(Math.random() * 16)]);
  }
  return result.join('').toUpperCase();
}


export function cloneVar(obj ){
  return JSON.parse(JSON.stringify(obj));
}

export function clone(obj ){
  return JSON.parse(JSON.stringify(obj));
}

/**
 * API를 호출할 서버 HOST를 가져온다.
 * @param str
 * @returns {*}
 */
// const getHostURL = (str='local') => {
//   //console.log( "getHostURL ---- ", apiServer);
//   return apiServer;
// };

export async function apiAuth(param){
  axiosConfig['responseType'] = 'json';
  try{
    const {data} = await axios.post( '/api/auth/login', param, axiosConfig );
    return data;
  }catch(err){
    return err.response?.data;
  }
}

export async function apiAuthMe(){
  axiosConfig['responseType'] = 'json';
  try{
    const {data} = await axios.get( '/api/auth/me', axiosConfig );
    return data;
  }catch(err){
    return err.response?.data;
  }
}

export async function apiAuthAccess(toRoute){
  axiosConfig['responseType'] = 'json';
  let user;
  try{
    if( !store.state.user._id ) {
      if( !localStorage.user ) {
        window.location.href= '/#/login';
        return {code: 401};
      }
      // console.log( 'apiAuthRoute---> localStorage user --->', localStorage.user );
      user = JSON.parse(localStorage.user);
      axios.defaults.headers.common['x-access-token'] = user.accessToken;
    } else {
      user = store.state.user;
    }
    // console.log( 'apiAuthRoute--->toRoute--->', toRoute);
    const param = {_id: user._id, id: user.id, role: user.role };
    const {data} = await axios.post('/api/auth/access',
        { user: param, route: toRoute },
        axiosConfig );
    // console.log( 'apiAuthRoute---response--->', data);
    return data;
  }catch(err){
    return err.response.data;
  }
}

export async function refreshToken(){
  axiosConfig['responseType'] = 'json';
  try{
    const {data} = await axios.get( '/api/auth/refresh', axiosConfig );
    return data;
  }catch(err){
    return err.response.data;
  }
}


export async function apiLogout(){
  axiosConfig['responseType'] = 'json';
  try{
    const {data} = await axios.get( '/api/auth/logout', axiosConfig );
    return data;
  }catch(err){
    return err.response.data;
  }
}

/**
 *
 * @param method
 * @param url
 * @param params
 * @param reason
 * @param result
 * @returns {Promise<any|T>}
 */
export async function accessLogging(method, router, params, reason='', result=''){
  axiosConfig['responseType'] = 'json';
  try{
    method = method.toUpperCase();

    const url = router.path;
    const resultText = `[${router.name}] ${result}`;
    const {data} = await axios.post(
        '/api/user/access',
        { method: method, url: url, body: params, reason: reason, result: resultText },
        axiosConfig );
    if( data.code===200 ) return true;
    else return false;
  }catch(err){
    console.error( 'accessLogging error--->', err )
    return false;
  }
}

/**
 * apiCall : REST METHOD call with resources and parameters
 * @param {String} method  get|post|put|delete|patch|GET|POST|PUT|DELETE|PATCH
 * @param {String} url  resource uri
 * @param {Object} param  json objects default null
 * @returns {Promise<Object>}
 */
export async function apiCall(method="GET", url="/",  param=null){
  let res = null, ret = null;


  const m = method.toUpperCase();
  axiosConfig['responseType'] = 'json';
  try{
    // console.log( `\t\t\t##### axios.${method} ----------> url : ${url} ----> param: ${JSON.stringify(param)}`);
    switch( m ){
      case 'GET': res = await axios.get( url, param ); ret = res.data; break;
      case 'POST': res = await axios.post( url, param, axiosConfig ); ret = res.data; break;
      case 'PUT':  res = await axios.put( url, param, axiosConfig ); ret = res.data; break;
      case 'DEL': res = await axios.delete( url, param); ret = res.data; break;
      case 'PATCH': res = await axios.patch( url, param, axiosConfig ); ret = res.data; break;
      case 'IMAGE': res = await axios.get( url, {'responseType': 'application/octet-stream'} ); ret = res;  break;
      case 'POSTFILE':
        axiosConfig['responseType'] = 'blob';
        res = await axios.post( url, param, axiosConfig );
        ret = res;
        break;
      case 'GETFILE':
        axiosConfig['responseType'] = 'blob';
        res = await axios.get( url, axiosConfig);
        ret = res;
        break;
      case 'PDF':
        axiosConfig['responseType'] = 'application/pdf';
        res = await axios.get( url, axiosConfig );
        ret = res;
        break;
      default:
        throw new Error('method not found');
    }
    return ret;

  }catch(err){
    // console.log( "###### util.apiCall------------- catch err ---> ", err.response );
    let code, message ;
    if(err.response && err.response.data){
      code = err.response.data.code;
      message = err.response.data.message;
      if( code >= 7400 && code < 8900){
        console.warn( '************ TOKEN ERR-------------> code, message', code, message )
        // alert(`[경고] 로그아웃 되었습니다.(${code})`);
        await store.dispatch('LOGOUT', code+'');
        // window.location.href = '/#/login';
        // window.close();
        // await router.push({name:'Login'});
      }else if( code===3209 || code===3201){
        await store.dispatch('LOGOUT', code+'');
        alert(`[경고] ${message}(${code})`);
        // window.location.href = '/#/login';
        // await router.push({name:'Login'});
      }else{
        throw new Error(err.response.data);
      }
      if( message ) throw new Error(`[${code}] ${message}`);
    }
    // console.log("------------server----error------------>", err)
    throw err;

  }
}

export async function apiMultiPart(resource="/",  formData ){

  let response = null, ret = null;
  let url = apiServer + resource;

  const config = {
    headers: { 'Content-Type': 'multipart/form-data'}
  };

  /*
  console.log( `\t\t##################### apiMultiPart()
  \t\t------------> method: POST
  \t\t------------> resource: ${url}
  \t\t------------> formData: --- `);
  */

  try{
    response = await axios.post( url, formData, config );
    // console.log( response );
    ret = response.data;
  }catch(err){
    console.error( "apiMultiPart-------------ERR ---> ", err );
    ret = err.response.data;
    // alert( err.message);

  }finally{
    // eslint-disable-next-line no-unsafe-finally
    return ret;
  }
}

const commaReg = /(^[+-]?\d+)(\d{3})/;   // 정규식
export function commify(n){
  if(n===null) return 0;
  n += '';                          // 숫자를 문자열로 변환
  while (commaReg.test(n)) {
    n = n.replace(commaReg, '$1' + ',' + '$2');
  }
  return n;
}

/**
 * 단위기호 리턴
 * @param s mm=미리미터, i=인치, l=리터, g=겔런, c=섭씨, f=화씨 d=드럼
 * @returns {string}
 */
export function unitSign(s){
  switch(s){
    case 'mm': return '㎜';
    case 'i': return 'in';
    case 'l': return 'L';
    case 'g': return 'gal';
    case 'd': return 'D';
    case 'b': return 'bbl';
    case 'c': return '°C';
    case 'f': return '°F';
  }
}

export function comma(n,round=0){
  if(n===null) return 0;
  n = n.toFixed(round); // 숫자를 문자열로 변환
  while (commaReg.test(n)) {
    n = n.replace(commaReg, '$1' + ',' + '$2');
  }
  return n;
}

/**
 *
 * @param val value
 * @param unitType volume or length
 * @param to trans to type
 * @returns {number|*|string}
 */
export function trans(val, unitType='v', to='l'){
  if(val===null) return '';
  val = Number(val);

  switch (unitType){
    case 'l': return (to==='i')? comma(val / 25.4,3):comma(val,0);
    case 'v': {
      if (to === 'g')  return comma(val / 3.78541, 0)
      else if ( to==='d') return comma(val / 200, 1)
      else if ( to==='b') return comma(val / 159, 1)
      else return comma(val)
    }
    case 't': return (to==='f')? comma(val*(9/5)+32)     :comma(val,1);
  }
}

export function ltr2gal(val=0, round=0){
  return comma( val * LTR2GL, round )
}
export function gal2ltr(val){
  return comma( val * GL2LTR )
}

export function tempC2F(v=0){
  return Number( ((v * 1.8)+32).toFixed(0) );
}

export function ltr2gl(v=0){
  return Number( (v * LTR2GL).toFixed(2) );
}

export function gl2ltr(v=0){
  return Number( (v * GL2LTR).toFixed(1) );
}

export function humanSize(bytes, si=true){
  let thresh = si ? 1000 : 1024;
  if(Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  let units = si
    ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
    : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while(Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1)+' '+units[u];
}

/**
 * HumanTime
 * @param temp second
 * @returns {string}
 */
export function humanTime( temp ){
// TIP: to find current time in milliseconds, use:
  // var  current_time_milliseconds = new Date().getTime();

  // temp --> unit second...
  // var temp = Math.floor(milliseconds / 1000);

  let years = Math.floor(temp / 31536000);
  if (years) {
    return years + ' 년';
  }
  //TODO: Months! Maybe weeks?
  let days = Math.floor((temp %= 31536000) / 86400);
  if (days) {
    return days + ' 일';
  }
  let hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
    return hours + ' 시간';
  }
  let minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
    return minutes + ' 분';
  }
  let seconds = temp % 60;
  if (seconds) {
    return seconds + ' 초';
  }
  return '1초 미만'; //'just now' //or other string you like;
}

export function getDiffSeconds(t1, t2=moment()) {
  if(!t1 || !t2) return -1;
  return moment.duration( t2.diff(t1) ).asSeconds();
}


/**
 * checkBizNo
 * @param bisNo
 * @returns {boolean}
 */
export function checkBisNo(bisNo){

  if(!bisNo) return false;
  // 넘어온 값의 정수만 추츨하여 문자열의 배열로 만들고 10자리 숫자인지 확인합니다.
  bisNo = (bisNo+'').match(/\d{1}/g);

  if( !bisNo ) return false;

  if ( bisNo.length != 10) return false;

  // 합 / 체크키
  let sum = 0, key = [1, 3, 7, 1, 3, 7, 1, 3, 5];

  for (let i = 0 ; i < 9 ; i++) { sum += (key[i] * Number(bisNo[i])); }
  let chkSum = 0;
  chkSum = Math.floor(key[8] * Number(bisNo[8]) / 10);
  sum +=chkSum;

  let reminder = (10 - (sum % 10)) % 10;
  if(reminder==Number(bisNo[9])) return true;

  return false;
}


/**
 *
 * @param centerCode
 * @param accessToken
 */
export async function connectWebSocket( centerCode, accessToken ){
  // let webSocket = store.state.socket;
  try{

    // console.warn( "******** connectWebSocket ---- state.socket ---->", store.state.socket );
    // console.warn( `******** connectWebSocket ---- connect -------- centerCode: ${centerCode}, accessToken: ${accessToken.slice(-10)}`);

    if( store.state.socket ){
      store.state.socket.disconnect();
      store.state.socket.removeAllListeners();
    }

    console.warn( "******** connectWebSocket ---- connecting" );
    store.state.socket = await socketClient('/' + centerCode, {
      extraHeaders: { 'x-access-token': accessToken },
      path: "/ws/socket.io",
      secure: true,
      reconnection: true,
      reconnectionAttempts:100,
      reconnectionDelay: 10*1000,
    }, 1500);


    store.state.socket.on('connect', (data) => {
      console.warn('******** connectWebSocket -----------> connected socket id ---> ', store.state.socket.id);
      store.state.serverConnected = true;
      // console.warn('******** connectWebSocket -----------> connected from server data---> ', data );
    });

    store.state.socket.on('time', (time) => {
      console.debug(time);
    });

    store.state.socket.on('disconnect', () => {
      console.warn('******** disconnect WebSocket ---- socket disconnect')
      store.state.serverConnected = false;
    });

    await sleep(1000);
    console.warn( "******** connectWebSocket ---- connected...", store.state.socket.connected );

    // store.commit('set', ['socket', webSocket])

  }catch(err){
    console.error(err);
  }



}

export function getR(degree){
  return degree * 3.14159265359 / 180
}


export function getHexColor(str){
  if(!str) return parseInt( '000000', 16);
  return parseInt(str.slice(-6), 16);
}


export async function setCodes(){
  try{
    // console.log("######------------ setting codes ----------");
    const cdRs = await apiCall('get', `/api/code-map/init`);
    if( cdRs.code===200) {
      store.state.codeMaps = cdRs.result.maps;
      store.state.codeOpts = cdRs.result.opts;
    }
  }catch(err){
    console.error(err);
  }
}

export async function getFrontMode(){
  try{
    const rs = await apiCall('get', `/api/access/front/mode`);
    // console.log("######------------ get front mode ----------> result ============>", rs);
    if( rs.code===200) {
      store.state.darkMode = rs.result.darkMode;
    }else{
      store.state.darkMode = true;
    }
  }catch(err){
    console.error(err);
  }
}


export async function setOil(){
  try{
    // console.log("[utils] ######------------ setting oils ----------");
    const cdRs = await apiCall('get', `/api/oil/init`);
    if( cdRs.code===200) {
      store.state.codeMaps['OIL_COLOR'] = cdRs.result.color;
      store.state.codeMaps['OIL_CODE'] = cdRs.result.code;
      store.state.codeMaps['OIL'] = cdRs.result.oil;
    }
  }catch(err){
    console.error(err);
  }
}

export async function setAlarm(){
  try{
    // console.log("[utils] ######------------ setting alarm ----------");
    const r1 = await apiCall('get', `/api/alarm/init`);
    const r2 = await apiCall('get', `/api/tank/alarm-config`);
    // console.log( 'setAlarm ---', r2 );
    if( r1.code===200) {
      store.state.alarm.config = r1.result;
    }
    if( r2.code===200){
      store.state.alarm.tank = r2.result;
      // console.log( 'setAlarm() --- store.state.alarm ---->', store.state.alarm );
    }
  }catch(err){
    console.error(err);
  }
}

export async function setAreas(){
  try{
    // console.log("[utils] ######------------ setting Areas ----------");
    // console.log( 'util --- setAreas' );
    const arRs = await apiCall('get', `/api/area/options`);
    if(arRs.code===200){
      store.state.area['map'] = arRs.result.map;
      store.state.area['maps'] = arRs.result.maps; // "[arCode] name"
      store.state.area['opts'] = arRs.result.opts;
      store.state.area['opts2'] = arRs.result.opts2; // Array
      store.state.area['codes'] = arRs.result.code; // Object
      // store.state.area.codes 해당 탱크 정보를 넣어 준다.
      let tanks = store.state.tanks.codes
      if(tanks){
        let areas = store.state.area['codes'];
        // console.log('store.state.tanks.codes => tanks---->', tanks)
        // console.log('Object.keys(tanks)---->', Object.keys(tanks))
        // console.log('area ---->',  areas )

        Object.keys(tanks).map(k=>{
          let t = tanks[k];
          let area = areas[t.arCode];
          if(area) area.tanks.push(t);
        })
      }

      // console.log( '@@@@@@@@@@@@@@@@@@@@@@@@@ store state area codes --->', store.state.area.codes );

      return true;
    }else{
      return false;
    }

  }catch(err){
    console.error("setAreas", err);
  }

}

export async function setTanks(){
  try{
    // console.log("[utils] ######------------ setting tanks ----------");
    // console.log( 'util --- setTanks' );

    const tankRs = await apiCall( 'get', `/api/tank/init`);
    if( tankRs.code===200){
      store.state.tanks['map'] = tankRs.result.map;
      store.state.tanks['opts'] = tankRs.result.opts;
      store.state.tanks['codes'] = tankRs.result.codes;
    }else{
      // alert('기초정보 구성 실패');
      return false;
    }

  }catch(err){
    console.error("setTanks", err);
  }
}







/***
 * 메시지 박스 출력
 * @param ctx $bvModal
 * @param msg { level, title, text }
 * @param callback
 */
export async function alertSync(ctx, msg){
  let variant = 'warning';
  const msgType = typeof msg;
  if( msgType === 'object' ){
    if( msg.level ){
      switch(msg.level){
        case 'info': variant = 'primary'; break;
        case 'warn': variant = 'warning'; break;
        case 'err': variant = 'danger'; break;
        case 'error': variant = 'danger'; break;
        case 'danger': variant = 'danger'; break;
        case 'primary': variant = 'primary'; break;
        case 'normal': variant = 'info'; break;
        default: variant= 'warning'; break;
      }
    }
    if( !msg.title ) msg['title'] = '[경고] Warning ';
    else msg.title = `[${msg.title}]`
  }else if(msgType==='string'){
    msg = { level: 'warning', text: msg, title: '[경과]'  }
  }else{
    return;
  }

  try{
    await ctx.msgBoxOk( msg.text, {
      title: msg.title,
      buttonSize: 'sm',
      headerBgVariant: variant,
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      okVariant: 'primary',
      centered: true, size: 'sm'
    });
  }catch(err){
    console.error("# alertAsync Error ---> ",err);
  }finally{
    // return r;
  }

}

/***
 * 메시지 박스 출력
 * @param ctx
 * @param msg
 * @param callback
 */
export async function alertWarn(ctx, msg, title=null){
  let r = null;
  try{
    await beepSound(6);
    r = await ctx.msgBoxOk( msg, {
      title: title? title:'[알림] Warning' ,
      headerBgVariant: 'warning',
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      okVariant: 'warning',
      centered: true, size: 'sm'
    });
    return r;
  }catch(err){
    console.error("# alertWarn Error ---> ",err);
  }
}


/**
 * Modal toastInfo with async promise
 * @param ctx : this.$bvToast
 * @param msg : String
 * @param title: String
 */
export async function toastSync(ctx, msg, type='danger',  title=null){
  if( !title ) title = '[정보] Information ';
  try{
    switch(type){
      case 'danger':await beepSound(7); break;
      case 'warning':await beepSound(6); break;
      case 'primary':await beepSound(5); break;
      case 'info':await beepSound(2); break;
      case 'success':await beepSound(1); break;
      default: await beepSound(1);
    }

    await ctx.toast( msg, {
      title: title,
      variant: type,
      autoHideDelay: 3000,
      appendToast: true
    })
  }catch(err){
    console.error("toastSync", err);
  }
}


/***
 * 메시지 박스 출력
 * @param ctx
 * @param msg
 * @param callback
 */
export async function modalWarn(ctx, msg, title=null){
  let r = null;
  try{
    await beepSound(6);
    r = await ctx.msgBoxOk( msg, {
      title: title? title:'[알림] Warning' ,
      headerBgVariant: 'warning',
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      okVariant: 'warning',
      centered: true, size: 'sm'
    });
    return r;
  }catch(err){
    console.error("modalWarn Error ---> ",err);
  }
}

/***
 * 메시지 박스 출력
 * @param ctx
 * @param msg
 * @param callback
 */
export async function modalSuccess(ctx, msg, title=null){
  let r = null;
  try{
    await beepSound(5);
    r = await ctx.msgBoxOk( msg, {
      title: (title)? title:'[알림] 완료' ,
      headerBgVariant: 'success',
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      okVariant: 'info',
      centered: true,
      size: 'sm'
    });
    return r;
  }catch(err){
    console.error("modalSuccess Error ---> ",err);
  }
}


/***
 * 메시지 박스 출력
 * @param ctx
 * @param msg
 * @param callback
 */
export async function alertError(ctx, msg, code=999){
  let r = null;
  try{
    await beepSound(7);

    r = await ctx.msgBoxOk( msg, {
      title: `[${code}] Error ` ,
      headerBgVariant: 'danger',
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      okVariant: 'danger',
      centered: true,
      size: 'sm'
    });
    return r;
  }catch(err){
    console.error("alertError Error ---> ",err);
  }
}


/***
 * 확인 메시지 박스 출력
 * @param ctx
 * @param msg
 * @param callback
 */
export async function alertConfirm (ctx, msg, title=null){
  let r = null;
  try{
    await beepSound(5);
    r = await ctx.msgBoxConfirm( msg, {
      title: (!title)?'[확인] Confirm':title,
      buttonSize: 'sm',
      headerBgVariant: 'warning',
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      okVariant: 'danger',
      centered: true, size: 'sm'
    });
    return r;
  }catch(err){
    console.error("alertConfirm Error ---> ",err);
  }
}

export function startFullScreen() {
  if (document.documentElement.requestFullscreen) {
    document.documentElement.requestFullscreen();
  } else if (document.documentElement.mozRequestFullScreen) { // Firefox
    document.documentElement.mozRequestFullScreen();
  } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari, Opera
    document.documentElement.webkitRequestFullscreen();
  } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
    document.documentElement.msRequestFullscreen();
  }
}

export function cancelFullScreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
}



/**
 * 지오메트리 병합 함수
 * @param geometries
 * @param positions
 * @returns {BufferGeometry}
 */
export function mergeBufferGeometries(geometries, positions) {
  const mergedGeometry = new THREE.BufferGeometry();

  // 병합할 버퍼 속성들
  const positionsArray = [];
  const normalsArray = [];
  const uvsArray = [];
  const indicesArray = [];

  let indexOffset = 0;

  geometries.forEach((geometry, geomIndex) => {
    const position = geometry.attributes.position.array;
    const normal = geometry.attributes.normal.array;
    const uv = geometry.attributes.uv ? geometry.attributes.uv.array : [];
    const index = geometry.index ? geometry.index.array : [];

    // position, normal, uv 배열 복사 및 위치 조정
    for (let i = 0; i < position.length; i += 3) {
      positionsArray.push(position[i] + positions[geomIndex].x);
      positionsArray.push(position[i + 1] + positions[geomIndex].y);
      positionsArray.push(position[i + 2] + positions[geomIndex].z);
    }

    for (let i = 0; i < normal.length; i++) {
      normalsArray.push(normal[i]);
    }

    for (let i = 0; i < uv.length; i++) {
      uvsArray.push(uv[i]);
    }

    // 인덱스 배열 복사
    for (let i = 0; i < index.length; i++) {
      indicesArray.push(index[i] + indexOffset);
    }

    indexOffset += position.length / 3;
  });

  // 속성 추가
  mergedGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positionsArray, 3));
  mergedGeometry.setAttribute('normal', new THREE.Float32BufferAttribute(normalsArray, 3));
  if (uvsArray.length > 0) {
    mergedGeometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvsArray, 2));
  }
  mergedGeometry.setIndex(indicesArray);

  return mergedGeometry;
}
