import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {
  cloneVar,
  apiAuth,
  apiAuthMe,
  apiLogout,
  refreshToken,
  connectWebSocket,
  beepSound,
  initBase, sleep
} from './common/utils'
import router from "@/router";


Vue.use(Vuex)

const _user = {
  _id        : null,
  id         : null,
  nick       : null,
  name       : null,
  position   : null,
  role       : null,
  email      : null,
  phoneNo    : null,
  accessToken :null,
  tokenExpireMin: 0,
  centerCode : null,
  areas: null,
  userClass : 0
};

const state = {
  serverConnected: false,
  largeFont: false,
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  darkMode: true,
  asideShow: false,
  numOfTankItem: 10,
  numOfMachineItem: 10,
  numOfInoutItem: 10,
  numOfTankStockItem: 10,
  numOfOilStockItem: 10,
  volUnit: 'g',
  lenUnit: 'i',
  tmpUnit: 'f',
  // ------------ below --- added for application ------------
  messageBarShow: false,
  messageBarMinimize: false,
  isAuth: false,
  expireSecIn: 0,
  soundOn: false,
  tankTrace: true,
  voice: null, // 목소리
  user: {
    _id        : null,
    id         : null,
    nick       : null,
    name       : null,
    position   : null,
    role       : null,
    email      : null,
    hpNo       : null,
    accessToken :null,
    tokenExpireMin: 0,
    centerCode  : null,
    areas: null,
    userClass : 0
  },
  socket: null,
  codeMaps: {},
  codeOpts: {},
  area: {},
  tanks: {},
  alarm: {
    count: {
      fromDays: 3,
      total: 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
    },
    config: {},
    tank: { },
  },
  updateStatus: {
    tank: { updated: false, appliedAt: null },
    tankTable: { updated: false, appliedAt: null },
    machine: { updated: false, appliedAt: null },
    alarm: { updated: false, appliedAt: null },
  },
  serverLiveness: false,
  // ------------ above --- added for application ------------

}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    // console.log( 'store.set() --- register global variable ---', variable);
    state[variable] = value
  },
  async toggle (state, variable) {
    state[variable] = !state[variable];
    await beepSound('success');
  },
  // ------------ below --- added for application ------------
  get (state, key) {
    return state[key];
  },
  async LOGIN (state, user) {
    // console.log( "**********************  store-mutation-LOGIN  **********************");
    state.user = user;
    localStorage.user = JSON.stringify(user);
    state.expireSecIn = (user.tokenExpireMin * 60) - 5;
    axios.defaults.headers.common['x-access-token'] = user.accessToken;
    state.isAuth = true;

    // console.log( "**********************  store-mutation-LOGIN --- state.user ---", state.user);

    await initBase();

    await sleep(1000);

    if( !Object.keys(state.codeMaps).length ) await sleep(200);
    if( !Object.keys(state.codeOpts).length ) await sleep(200);
    if( !Object.keys(state.area).length ) await sleep(200);
    if( !Object.keys(state.tanks).length ) await sleep(200);

    await connectWebSocket(user.centerCode, user.accessToken);

  },

  setToken (state, auth) {
    // console.log("========= store-mutation-setToken ========= ");
    state.user.accessToken = auth.accessToken;
    state.user.tokenExpireMin = auth.tokenExpireMin;
    state.expireSecIn = (auth.tokenExpireMin * 60) - 5;
    localStorage.user = JSON.stringify(state.user);
    setTimeout( async ()=>{
      await connectWebSocket(state.user.centerCode, state.user.accessToken);
    }, 2000 );

  },

  async LOGOUT (state, code) {
    // console.log("========= store-mutation-LOGOUT ========= code------->", code);
    try{
      state.user.accessToken = null;
      state.isAuth = false;
      state.user = cloneVar( _user );
      // $user = cloneVar( _user );
      // $isAuthed = false;
      state.socket = null;
      delete localStorage.user;
      await router.push({name:'Login', params: {code} }, );

    }catch(err){
      console.error( "========= store-mutation-LOGOUT ----->logout error--->", err );
    }
  }

  // ------------ above --- added for application ------------

}

const classMap = { SA: 1, SM: 2, SS:3 };

const actions = {
  async LOGIN ({commit}, {userId, userPwd }) {
    // console.log( "*** ------------------- store----action-----LOGIN -------------------- ***");

    const param = {
      loginId: userId,
      loginPwd: userPwd,
    };

    // const encodedParam = qs.stringify( param );
    // const serverUrl = $baseURL;
    try{
      const rs = await apiAuth( param );
      // console.log("############ STORE_ACTION___LOGIN  ===============>  result: ", rs );

      if( rs.code===200 ) {
        if (!rs.result.accessToken)
          throw new Error("no Access Token");

        axios.defaults.headers.common['x-access-token'] = rs.result.accessToken;

        const {user} = rs.result;

        if (user) new Error("cannot get user info");
        const {accessToken} = rs.result;

        const commitUser = {
          _id: user._id,
          id: user.userId,
          nick: user.nick,
          name: user.name,
          position: user.position,
          role: user.role,
          areas: user.areas,
          email: user.email,
          hpNo: user.hpNo,
          centerCode: user.centerCode,
          wgCode: user.wgCode,
          tokenExpireMin: user.tokenExpireMin,
          accessToken: accessToken,
          userClass: classMap[user.role]
        };

        // console.log('################ STORE_ACTION___LOGIN ----user---------->', commitUser);

        commit('LOGIN', commitUser);
      }

      return rs;

    }catch(err){
      console.error("################ STORE___ACTION___LOGIN error: ", err );
      // alert("SYSTEM NOT Ready...try again");
      // commit('LOGOUT');
      throw err;
    }

  },

  async LOGOUT ({commit}, code) {
    // console.log('################ STORE_ACTION___LOGOUT ################ code =>', code);
    try{
      if(!code) code = '0000';
      if( axios.defaults.headers.common['x-access-token'] ){
        const rs = await apiLogout();
        console.log( "################ STORE_ACTION___LOGOUT ----->logout result--->", rs );
      }

      if(state.socket){
        // console.log( "################ STORE_ACTION___LOGOUT-----> socket: ", state.socket );
        state.socket.removeAllListeners();
        state.socket.disconnect();
      }
      await commit('LOGOUT', code+'');

    }catch(err){
      console.error("################ STORE_ACTION___LOGOUT Error:", err)
    }finally{
      axios.defaults.headers.common['x-access-token'] = undefined;
    }

  },

  async refreshToken({commit}) {
    // console.log('################ STORE_ACTION___refreshToken ################');
    const rs = await refreshToken();
    if(rs.code===200){
      axios.defaults.headers.common['x-access-token'] = rs.result.accessToken;
      await commit('setToken', rs.result); // mutation call
      // await commit('LOGIN', rs.result); // mutation call
      return true;
    }else{
      return false;
    }
  },

  async setLoginState({commit}, user){
    try{
      axios.defaults.headers.common['x-access-token'] = user.accessToken;
      const rs = await apiAuthMe();
      // console.log( "@@@@@@@@@@ setLoginState() --- apiAuthMe()---------------> result --------->", rs );
      if( rs.code===200 ) {
        if (!rs.result.accessToken)
          throw new Error("no Access Token");

        axios.defaults.headers.common['x-access-token'] = rs.result.accessToken;
        const {user} = rs.result;

        if (user) new Error("cannot get user info");

        const commitUser = {
          _id: user._id,
          id: user.userId,
          nick: user.nick,
          name: user.name,
          position: user.position,
          role: user.role,
          areas: user.areas,
          email: user.email,
          hpNo: user.hpNo,
          centerCode: user.centerCode,
          accessToken: rs.result.accessToken,
          tokenExpireMin: rs.result.tokenExpireMin,
          userClass: classMap[user.role]
        };



        // console.log('@@@@@@@@@@ setLoginState() ----> commit user---------->', commitUser);
        await commit('LOGIN', commitUser);
        return true;

      } else {
        commit('LOGOUT', 'E401');
        return false;
      }
    }catch(err){
      console.error( "@@@@@@@@@@ setLoginState ------------> ", err.message );
      commit('LOGOUT', 'E500');
      throw err;
    }
  }
};


export default new Vuex.Store({
  state,
  mutations,
  actions
})
