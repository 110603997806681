
  export const TankSchema = {
    tid: '',
    order: 1, // 표시 순서
    tankCode: '', // 탱크코드
    name: 'tank-name',
    color: '#FFFFFF',
    oilCode: '3', // 경유
    oilTempGroup: '3',
    tankUse: '1',
    tankType: '1', // 탱크타입
    tankShape: '1',
    sensorType: '1',
    arCode: '', // 그룹코드
    dfCode: '', // Deliis/F 연동코드
    location: { type: 'Point', coordinates: [0,0] }, // GPS 좌표
    position: { x: 0, y: 0, z: 0, ax:0, ay:0, az:0, cx:0, cy:0, cz:0, ly:0, lyd: 0 }, // 3D Map 좌표, 조정좌표, 카메라좌표, 라벨높이, 라벨각도

    tankWidth: 30000,
    tankHeight: 33000,
    tankVolume: 50000, // 저장용량
    realVolRate: 0.95, // 실저장용량비율
    tankRealVol: Math.ceil(50000 * 0.95), // 실저장 용량
    volRevision: 0, // 환산재고 보정값 (공군 deliis/f 전송시)
    saveInterval: 300, // 재고저장 인터벌 (초)
    eventInterval: 60, // 재고저장 인터벌 (초)
    thresholds: {
      fire_cs: 40,
      water_mm: 100,
      leakRate: 0.005,
      leakIntSecs: 60, // 누유체크 시간 기본 60초 (60초 이전과 비교)
      overflowRate1: 0.85, // 첫번째 넘침경보 알람 저장률 0.85
      overflowRate2: 0.9, // 두번째 넘침경보 알람 저장률 0.90
      overflowLtr1: Math.ceil(50000 * 0.95 * 0.85), // 첫번째 넘침경보 알람 저장량 (liter)
      overflowLtr2: Math.ceil(50000 * 0.95 * 0.95), // 두번째 넘침경보 알람 저장량 (liter)
      underflowRate1: 0.10, // 첫번째 부족경보 알람 저장률 ex) 0.10
      underflowRate2: 0.05, // 두번째 부족경보 알람 저장률 ex) 0.05
      underflowLtr1: Math.ceil(50000 * 0.95 * 0.1), // 첫번째 부족경보 알람 저장량 (liter)
      underflowLtr2: Math.ceil(50000 * 0.95 * 0.05), // 두번째 부족경보 알람 저장량 (liter)
    },
    checkNoise : true, // 노이즈 체크 여부
    oilNoiseLimit : 100, // 노이즈 체크 리밋 (오일플롯 차이, mm)
    wtrNoiseLimit : 100, // 노이즈 체크 리밋 (수분플롯 차이, mm)
    eventEnabled: true,
    alarm: {
      enabled: true,
      console: {
        icon: true,
        sound: true,
        tts: true,
      },
      lamp: {
        light: true,
        buzzer: true
      },
      events: [
        {
          code: {type: String},
          enabled: true,
        }
      ],

    },
    machine: null, // 장비 ref
    nozType: '1',
    nozSize: '1',
    cleanDate: null, // 세척일
    maDate: null, // 유지보수일 FIX_DATE
    comment: '',
    display: true,
    isTwin: true,
    unitOfVol: 'l',
    unitOfLen: 'mm',
    unitOfTmp: 'c',
    senderCode: '', // interface 코드
    waterFloat: '', // ???
  };


  export const TankColumnMap = {
    'tid': '탱크아이디',
    'order': '표시순서',
    'name': '탱크명',
    'tankCode': '탱크코드',
    'color': '탱크칼라',
    'oilCode': '유종코드',
    'tankType': '탱크타입',
    'tankUse': '사용형태',
    'tankShape': '탱크모영',
    'arCode': '관제지역',
    'dfCode': '연동코드',
    'tankHeight': '탱크높이',
    'tankVolume': '저장용량',
    'realVolRate': '실저장용량',
    'volRevision': '환산재고보정값',
    'thresholds.fire_cs': '고온경보온도',
    'thresholds.water_mm': '수분경보높이',
    'thresholds.leakRate': '누유경보율',
    'thresholds.overflowRate1': '넘침경보1 저장률',
    'thresholds.overflowRate2': '넘침경보2 저장률',
    'thresholds.overflowLtr1': '넘침경보1 저장량',
    'thresholds.overflowLtr2': '넘침경보2 저장량',
    'thresholds.underflowRate1': '부족경보1 저장률',
    'thresholds.underflowRate2': '부족경보2 저장률',
    'thresholds.underflowLtr1': '부족경보1 저장량',
    'thresholds.underflowLtr2': '부족경보1 저장량',
    'checkNoise' : '노이즈 체크',
    'oilNoiseLimit' : '유위 임계치(mm)',
    'wtrNoiseLimit' : '수위 임계치(mm)',
    'alarms.overflow': '넘침 알람',
    'alarm.lack': '부족 알람',
    'alarm.fire': '고온 알람',
    'alarm.water': '수분 알람',
    'alarm.leak': '누유 알람',
    'alarm.tts': '콘솔음성',
    'alarm.sound': '콘솔사운드',
    'alarm.icon': '콘솔아이콘',
    'alarm.lamp': '경광등',
    'alarm.lampSound': '경광등사운드',
    'machine': '장비정보',
    'sensorType': '센서타입',
    'nozType': '노즐형태',
    'nozSize': '노즐크기',
    'cleanDate': '세척일시',
    'maDate': '보수일시',
    'comment': '비고',
    'saveInterval': '재고저장시간',
    'eventInterval': '이벤트저장시간',
    'eventEnabled': '경보알림',
    'senderCode': '인터페이스아이디',
    'waterFloat': '수분플롯',
    'location':'탱크위치',
    'display': '표시여부',
    'keKey': '키암호화키',
    'keIv': 'Kek IV',
    'devKey': '단말키',
    'devIv': '단말IV',
  };


  export const MachineSchema = {
    mid           : '',
    tank          : null,
    type          : '1', // 센서타입
    name          : '',
    serialNo      : '',
    version       : '01',
    company       : '', // 제조사
    productName   : '', // 제품명
    modelName     : '', // 모델코드
    linkMid       : '', // 연결된 mid (장비아이디)
    linkType      : '3', // LINK_TYPE  (zigbee, serial, tcp/ip, radio)
    linkMchType   : '4', // LINK_MTYPE, MACHINE_TYPE  (1:센서, 2:중계기, 3:컨트롤러, 4:IGS)
    linkDevId     : '', // 연결장비 LoRa EUI
    packetType    : 'ATG_TCP', // { ATG_TCP, TOKYO_KEISO... }
    deviceIp      : '', // 장비IP

    devKey        : '', // 단말기 MK
    devIv         : '', // 단말기 초기 벡터
    keKey         : '', // 키 암호화 키
    keIv          : '', // 키 암호화 IV
    cMK           : '', // 현재 MK
    cIV           : '', // 현재 IV
    keyIssAt      : null, // 키갱신일
    keyEndAt      : null, // 키만료일
    keyExpAt      : null, // 키만료일
    keyAppAt      : null, // 적용일
    isKeyApplied  : false, // 적용여부
    isKeyExported : false, // 다운로드여부
    keyIssId       : '', // 키 갱신자 아이디
    keyAppId       : '', // 키 갱신자 아이디
    keyExpId       : '', // 키 다운로드 아이디

    sensorType    : '1', // 1:Magneto. 2:Servo. 3:Radar. 4:Etc.
    sensorLen     : 9400, // 센서 길이
    gainValue     : 0.245, // SENSOR_GAIN
    sensorWater   : 100, // SENSOR_WATER - 수분플롯 높이 mm
    pipeType      : 'M', // SENSOR_PIPE - S:small, M:medium, L:large
    joinLength    : 1500,   // SENSOR_JOIN - 체결길이 mm
    numOfTemp     : 1,
    tempSinkRev   : 100,
    temps : [
      {
        idx : 0,
        location : 8500, // 설치 위치 (mm)
        rev      : 0,  // 보정값
        status   : 'NA', // 센서상태 NA, CN, DC, ER
      }
    ],
    numOfRev: 0,
    revisions : [],
    oilRevBase    : 0, // 오일 플로우트 보정 높이
    wtrRevBase    : 0, // 수분 프로우트 보정 높이
    thresholds: {
      voltage  : 5.5, // 전압 기준값 이하 알람
      signal   : 80, // 신호 세기 이하 알람
    },
    serviceType   : '1', // ATG 서비스, '2' - 커스텀구분
    installDate   : null,
    linkDate      : null,
    linkStatus    : 'NA',
    warrantyStart : null,
    warrantyEnd   : null,
    comment       : '',
  };

  export const MachineColumnMap = {
    mid           : '장비ID',
    tank          : '탱크정보',
    type          : '유형그룹코드',
    name          : '장비명',
    productName   : '제품명',
    modelName     : '모델명',
    version       : '버전',
    serialNo      : '시리얼번호',
    company       : '제조사',
    linkMid       : '연결장비ID',
    linkType      : '연결프로토콜',
    linkMchType   : '연결장비종류',
    linkDevId     : '단말EUI',
    packetType    : '패킷종류',
    deviceIp      : '장비IP',
    devKey        : '단말Key',
    devIv         : '단말초기벡터',
    keKey         : '키암호화키',
    keIv          : '키암호화IV',
    cMK           : 'MK',
    cIV           : 'IV', //
    keyIssAt      : '키갱신일', //
    keyEndAt      : '키만료일', //
    keyExpAt      : '키추출일', //
    keyAppAt      : '키적용일', //
    isKeyApplied  : '키적용YN', //
    isKeyExported : '다운로드YN', //
    keyIssId       : '키 갱신자 아이디', //
    keyAppId       : '키 갱신자 아이디', //
    keyExpId       : '키 추출자 아이디', //
    sensorType    : '센서종류',
    sensorLen     : '센서길이',
    pipeType      : '센서크기', // SENSOR_PIPE - S:small, M:medium, L:large
    joinLength    : '체결길이',   // SENSOR_JOIN - 체결길이 mm
    gainValue     : 'Gain값', // SENSOR_GAIN
    sensorWater   : '플롯높이', // SENSOR_WATER - 수분플롯길이 mm
    oilRevBase    : '오일보정높이', // 오일 플로우트 보정 높이
    wtrRevBase    : '수분보정높이', // 수분 프로우트 보정 높이
    numOfTemp     : '온도계수',
    tempSinkRev   : '유효온도계 보정높이', // 유위(oh)에 잠긴 온도계 높이 기준값
    'temps.idx'      : '온도계번호',
    'temps.location' : '온도계설치위치',
    'temps.rev'      : '보정온도',
    'temps.status'   : '상태',
    numOfRev     : '구간보정수',
    'revisions.idx'  : '보정번호',
    'revisions.from' : '시작위치',
    'revisions.to'   : '종료위치',
    'revisions.rev'  : '보정치mm',
    'thresholds.voltage' : '전압기준값',
    'thresholds.signal'   : '신호세기기준값',
    serviceType   : '서비스 구분',
    installDate   : '설치일시',
    linkDate      : '연동일시',
    linkStatus    : '연결상태', // NA, CN, DC, ER
    warrantyStart : '보증시작일',
    warrantyEnd   : '보증종료일',
    comment       : '비고',
    updId         : '수정자',
    regId         : '등록자'
  };


  export const PacketSchema = {
    dts            : '',
    dt             : null,
    fno            : '',
    fcd            : '',   // -----------> smart-pol -- function code
    cid            : '',
    sid            : '',
    tid            : '',
    mid            : '',
    acd            : '', // 지역 코드
    ocd            : '', // 유종 코드
    otg            : '', // 유종 그룹
    use            : '',
    osv            : 0, // 오일 센서 값
    wsv            : 0, // 수분 센서 값
    oh             : 0, // 오일 높이 = sensorLength - ( osv * gainValue )
    wh             : 0, // 수분 높이 = sensorLength - ( wsv * gainValue )
    ohr            : 0, // Oil Revision Height: 오일 계산 높이 = oh + oilRevBase + 구간 보정값
    whr            : 0, // wtr Revision Height: 수분 계산 높이 = wh + wtrRevBase
    ov             : 0, // oil Volume - 탱크 테이블의 재고 량
    wv             : 0, // water Volume - 탱크 테이블의 재고 량
    ovm            : 0, // Oil Volume Measured 측정(실)재고 = 유량-수분량 (oilVol - wtrVol) = measure volume
    ovc            : 0, // Oil Volume Conversion 체적 환산 재고 = 온도(F)에 따른 유종그룹의 계수 * 측정재고(ovm)
    ovcr           : 0, // oil volume Conversion Revision 보정 재고 = ovc + tank.volRevision
    tcrt           : 1, // 적용된 체적 환산 계수
    tm1            : null,
    tm2            : null,
    tm3            : null,
    tm4            : null, // 신규
    tm5            : null, // 신규
    tm6            : null, // 신규
    tm7            : null, // 신규
    tm8            : null, // 신규
    tm9            : null, // 신규
    rsi            : 0, // rssi
    btr            : 0, // battery voltage
    useTms         : [], // 유효 온도계 번호
    numTm          : 0,   // 유효 온도계 수
    avgTm          : 0,
    minTm          : 0,
    maxTm          : 0,
    avgTmF         : 0, // 평균 화씨(F) 온도
    tnm            : '',
    ofv            : null,    // -----------> smart-pol 탱크로리 ---- oil first sensor value , fcd 가 2,3,4인 경우
    ofh            : null,    // -----------> smart-pol 탱크로리 ---- oil first height - computed, fcd 가 2,3,4인 경우
    ofvc           : null,    // -----------> smart-pol 탱크로리 ---- oil first value - computed , conversion
    ofvcr          : null,     // -----------> smart-pol 탱크로리 ---- oil first value - computed , conversion + revision
    ofm            : 0,    // -----------> smart-pol 탱크로리 ---- oil first Measured Volume, fcd 가 2,3,4인 경우
    eqcd           : '',   // -----------> smart-pol 탱크로리 --- 장비코드
    eqid           : '',   // -----------> smart-pol 탱크로리 --- 기종번호
    eqnm           : '',
    gpsXy          : '', // ecnrypted
    location       : { type: 'Point',  coordinates: [0,0] }, // ----------> smart-pol 탱크로리
    map            : null, // smart-pol 지도상 표시 좌표 xyz
    slen           : 0,
    gval           : 0,
    tv             : 0, // 총 저장 용량
    trv            : 0, // 실저장 용량 tankVolume * tankVolRate
    tvp            : 0, // 충족률 = oilVol / tankVolume * 100
    vf             : 0, // 잔여저장능력 = tankRealVol - oilRealVol
    weight         : 0, // 무게
    isFuel         : false, // -----------> smart-pol 탱크로리 --- 급유/배유/인증시 true
    isTst          : false,// -----------> smart-pol 테스트 패킷인 경우 true
    // ioSts          : 'N',
    sts             : {
      code      : '0000', // 0000 정상
      message   : '',
      updatedAt : null,
      io        : 'N', // InOut 상태 I:입고, O:출고
      ioAt      : null, // inout status at
    }, // = tank.sts ---> 탱크,이동체 상태 추가 2024.6.15
    packet         : null, // packet._id
    isErr          : false,
    isEvt          : false,
    events         : [],
    errs           : [],
    messages       : []
  }



  export const InoutLogSchema = {
    tid        : null,
    ttid       : null,
    tnm        : null,
    tcd        : null,
    ttnm       : null,
    arcd       : null,
    dfcd       : null,
    eqnm       : null,
    eqid       : null,
    ano        : null,
    mdcd       : null,
    senType    : null,
    sts        : null,
    ocd        : null,
    bgDt       : null,
    bgOhr      : null,
    bgOvm      : null,
    bgOvc      : null,
    bgAvgTm    : null,
    bgInventory: null,
    edDt       : null,
    edOhr      : null,
    edOvm      : null,
    edAvgTmp   : null,
    edInventory: null,
    adjOv      : null,
    diffOhr    : null,
    diffOvm    : null,
    diffOvc    : null,
    inOvm      : null,
    inOvc      : null,
    outOvm     : null,
    outOvc     : null,
    adjRemark  : null,
    opRemark   : null,
    isDone     : null,
    endDt      : null,
    regId      : null,
    updId      : null,
  };


  export const InoutColMap = {
    tid         : '탱크번호',
    ttid        : '타켓탱크',
    tnm         : '탱크명',
    tcd         : '탱크코드',
    ttnm        : '타켓탱크명',
    arcd        : '지역코드',
    dfcd        : 'DF코드',
    eqnm        : '기기명',
    eqid        : '기기번호',
    ano         : '인증번호',
    mdcd        : '수령인구분',
    senType     : '측정방식',
    sts         : '입출고',
    ocd         : '유종코드',
    bgDt        : '시작일시',
    bgOhr       : '시작위치',
    bgOvm       : '시작유량',
    bgOvc       : '시작환산',
    bgAvgTm     : '시작온도',
    bgInventory : '시작재고량',
    targetVol   : '목표량',
    edDt        : '종료일시',
    edOhr       : '종료위치',
    edOvm       : '종료유량',
    edOvc       : '종료환산',
    edAvgTm     : '종료온도',
    edInventory : '종료재고량',
    adjOv       : '조정량',
    diffOhr     : '변동높이',
    diffOvm     : '변동유량',
    diffOvc     : '변동환산',
    inOvm       : '입고량',
    inOvc       : '환산입고량',
    outOvm      : '출고량',
    outOvc      : '환산출고량',
    adjRemark   : '조정사유',
    opRemark    : '작업설명',
    isDone      : '완료',
    regId       : '',
    updId       : '',
  };

  export const ConsumeColMap = {
    SPPRT_UC         : '비행단 부대코드',
    STRG_UC          : '비행단 보급대대 부대코드',
    LOTNO            : '로트번호',
    PRSC_DATE	       : '시효일자',
    MFGDT	           : '제조일자',
    FSC	             : '군급분류코드',
    NIIN	           : '국가품목식별번호',
    BDGT_PRJ_CD	     : '예산사업코드',
    SOCD	           : '보급목적코드',
    INDVD_SN	       : '개체일련번호',
    REFL_ISSU_RTNQTY : '급유불출반납량',
    RCPNT_SRVNO	     : '수령인군번',
    RCPNT_MDCD       : '수령인군구분',
    RMRK	           : '비고',
    REFL_VHCLNO	     : '급유차량번호',
    MCCD	           : '물품상태코드',
    STRLOC	         : '저장위치',
    UICD	           : '단위코드',
    WHSNO	           : '창고번호',
    STRG_FCLTNO	     : '저장시설번호',
    TOC	             : '거래사유코드',
    ORGNZT_UC	       : '편성부대코드',
    RCPT_INDVD_SN	   : '수령장비개체일련번호',
    RCPT_FSC	       : '수령장비군급분류코드',
    RCPT_NIIN	       : '수령장비국가품목식별번호',
    ISSU_RTNDT	     : '불출반납일자'
  };

  export const UserSchema = {
    userId         : '',
    name           : '',
    nick           : '',
    grade          : '',
    position       : '',
    department     : '',
    email          : '',
    hpNo           : '',
    phoneNo        : '',
    role           : '',
    sts            : 'RG',
    wgCode         : 'DEFAULT',
    hash           : '',
    hashes         : [],
    failCount      : 0,
    isLoggedIn     : false,
    lastLoginLimit : 14,
    lastLoggedInAt : null,
    tokenId        : '',
    ipAddr         : '',
    allowedIpAddrs : '',
    result         : '',
    isInitPwd      : false,
    isLocked       : true,
    isDeleted      : false,
    lockedAt       : null,
    reasonLock     : '',
    pwdExpiresAt   : null,
    tokenExpireMin : 60,
    device         : '',
    salt           : '',
    areas          : []
  };


  export const UserColMap = {
    userId         : '아이디',
    name           : '성명',
    nick           : '표시명',
    grade          : '직급',
    position       : '직책',
    department     : '부서',
    email          : '이메일',
    hpNo           : '휴대전화번호',
    phoneNo        : '전화번호',
    role           : '권한',
    sts            : '계정상태', // {RG:'최초등록', PW:'비밀번호 변경', PI:'비밀번호 초기화', LK:'잠김' NR:'정상'}
    wgCode         : '워크그룹',
    hash           : '해쉬',
    hashes         : '해쉬이력',
    failCount      : '실패건수',
    isLoggedIn     : '로그인여부',
    lastLoginLimit : '미사용 잠금일 수',
    lastLoggedInAt : '로그인 일시',
    tokenId        : '토큰ID',
    ipAddr         : '접속IP',
    allowedIpAddrs : '허용IP',
    result         : '로그인결과',
    isInitPwd      : '비밀번호초기화 여부',
    isApproved     : '사용여부',
    isLocked       : '계정잠금여부',
    isDeleted      : '삭제여부',
    lockedAt       : '잠금일시',
    reasonLock     : '잠금사유',
    pwdExpiresAt   : '비밀번호 만료일시',
    tokenExpireMin : '토큰만료(분)',
    device         : '접속 디바이스',
    salt           : 'Salt',
    areas          : '관제지역',
    regId          : '등록자', // 등록자
    updId          : '수정자', // 수정자
  };
